<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-multiline is-vcentered">
        <div class="column is-12">
          <p class="has-text-weight-bold has-text-centered paragraph">
            {{ title }}
          </p>
        </div>
        <div class="column is-12 is-flex is-justify-content-space-evenly is-align-items-center">
          <img class="icon_card mx-1" :src="require(`@/assets/icon/${icon}`)" :alt="title">
          <p class="text_51 mx-1 has-text-weight-bold has-text-centered" :class="primaryColor">
            {{ total }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardQuantities',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    total: {
      type: Number,
      default: 0
    },
    primaryColor: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
  }
  .card {
    height: 100%;
  }
  .icon_card {
    width: 32px;
  }
  .paragraph {
    min-height: 2rem;
  }
</style>
